<template>
  <div
    class="
      relative
      flex
      min-h-screen
      flex-col
      justify-center
      overflow-hidden
      bg-gray-50
      py-6
      sm:py-12
    "
  >
    <!-- <div class="w-1/2 h-96 m-auto"> -->
    <div
      class="
        relative
        bg-white
        px-6
        pt-10
        pb-8
        shadow-xl
        ring-1 ring-gray-900/5
        sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10
      "
    >
      <qrcode-stream @decode="onDecode" :track="drawOutline" @init="onInit">
        <div class="loading-indicator" v-if="loading">Loading...</div>
      </qrcode-stream>
      <div class="pt-8 text-base font-semibold leading-7">
        <div class="px-6 pb-2">
          <p class="text-gray-900">LLF?</p>
          <p>{{ error }}</p>
          <p class="text-gray-700 text-base">{{ guestDetails.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue3-qrcode-reader";
import axios from "axios";
import store from "@/store";
export default {
  data() {
    return {
      error: "",
      guest: {
        decodedText: "",
      },
      decodedText: "",
      // qrcode: "",
      loading: false,
      guestDetails: "",
    };
  },
  components: {
    QrcodeStream,
  },
  methods: {
    async onInit(promise) {
      // show loading indicator
      this.loading = true;
      try {
        const { capabilities } = await promise;

        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = " user denied camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = " no suitable camera device installed";
        } else if (error.name === "NotSupportedError") {
          this.error = "page is not served over HTTPS (or localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = " maybe camera is already in use";
        } else if (error.name === "OverconstrainedError") {
          this.error =
            "did you requested the front camera although there is none?";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "browser seems to be lacking features";
        }
      } finally {
        // hide loading indicator
        this.loading = false;
      }
    },
    onDecode(decodeString) {
      this.guest.decodedText = decodeString;
      decodeString = this.guest.decodedText;

      store
        .dispatch("scannedGuest", { decodedString: this.guest.decodedText })
        .then(({ data }) => {
          this.$swal.fire({
            icon: "success",
            title: "You have been scanned",
            text: data.guest.name,
          });
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data,
          });
        });
      setTimeout(this.onDecode, 5000);
    },
    drawOutline(decodedData, context) {
      var points = [];
      for (var k in decodedData) {
        switch (k) {
          case "topLeftCorner":
            points[0] = decodedData[k];
            break;

          case "topRightCorner":
            points[1] = decodedData[k];
            break;

          case "bottomRightCorner":
            points[2] = decodedData[k];
            break;
          case "bottomLeftCorner":
            points[3] = decodedData[k];
            break;

          default:
            break;
        }
      }
      context.lineWidth = 10;
      context.strokeStyle = "green";
      context.beginPath();
      context.moveTo(points[0].x, points[0].y);

      for (const { x, y } of points) {
        context.lineTo(x, y);
      }
      context.lineTo(points[0].x, points[0].y);
      context.closePath();
      context.stroke();
    },
  },
};
</script>