<template>
  <div class="">
    <qrcodescanner />
  </div>
</template>

<script>
import QrCodeScanner from "@/components/QrCode/QrCodeScanner.vue";
// @ is an alias to /src

export default {
  components: {
    qrcodescanner: QrCodeScanner,
  },
  name: "HomeView",
};
</script>
