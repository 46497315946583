<template>
  <div
    class="bg-gray-200 dark:bg-gray-900 flex items-center justify-center"
    style="font-family: 'Lato', sans-serif"
  >
    <form @submit.prevent="onSubmit">
      <div class="flex md:flex-row flex-col items-center py-8 px-4">
        <!-- Code block starts -->
        <div class="flex flex-col md:mr-16">
          <label
            for="name"
            class="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2"
            >Full Name</label
          >
          <input
            id="name"
            v-model="name"
            class="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow"
            placeholder="Ogunlana Michael"
            required
          />
        </div>
        <!-- Code block ends -->
        <!-- Code block starts -->
        <div class="flex flex-col md:mr-16">
          <label
            for="email"
            class="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2"
            >Email</label
          >
          <input
            id="email"
            v-model="email"
            type="email"
            class="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow"
            placeholder="example@example.com"
            required
          />
        </div>
        <!-- Code block ends -->
        <!-- Code block starts -->
        <div class="flex flex-col md:py-0 py-4">
          <label
            for="phone"
            class="md:pt-4 text-gray-400 text-sm font-bold leading-tight tracking-normal mb-2"
            >GENDER</label
          >
          <select
            required
            class="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow"
            v-model="gender"
          >
            <option value="male">MALE</option>
            <option value="female">FEMALE</option>
          </select>
        </div>
        <!-- Code block ends -->
      </div>
      <div>
        <button
          type="submit"
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon
              class="h-5 w-5 text-gray-900 group-hover:text-indigo-400"
              aria-hidden="true"
            />
          </span>
          Save Guest
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import store from "@/store";
import Swal from "sweetalert2";
import { ref, computed } from "vue";

const name = ref("");
const email = ref("");
const gender = ref("");
function onSubmit(ev) {
  ev.preventDefault();
  const data = {
    name: name.value,
    email: email.value,
    gender: gender.value,
  };

  store
    .dispatch("savedEvents", data)
    .then(() => {
      name.value = "";
      email.value = "";
      gender.value = "";

      Swal.fire({
        title: "Success!",
        text: "Event has been added",
        icon: "success",
        confirmButtonText: "OK",
      });
    })
    .catch((err) => {
      // console.log("ERROR", err);
      Swal.fire({
        title: "Error!",
        text: "Event has not been added",
        icon: "error",
        confirmButtonText: "OK",
      });
    });
}
</script>
