<template>
  <PageComponent>
    <template v-slot:header>
      <div class="flex justify-between items-center">
        <h1 class="text-3xl font-bold text-gray-900">Attendance</h1>
        <router-link
          :to="{ name: 'AttendanceCreate' }"
          class="
            py-2
            px-3
            text-white
            bg-blue-500
            rounded-md
            hover:bg-indigo-600
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 inline-block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4v16m8-8H4"
            />
          </svg>
          Attendance
        </router-link>
      </div>
    </template>
    <div v-if="attendances.loading">
      <LoadindView />
    </div>
    <div v-else>
      <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div
          class="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
        >
          <table class="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  class="
                    px-5
                    py-3
                    border-b-2 border-gray-200
                    bg-gray-100
                    text-left text-xs
                    font-semibold
                    text-gray-700
                    uppercase
                    tracking-wider
                  "
                >
                  S/N
                </th>
                <th
                  class="
                    px-5
                    py-3
                    border-b-2 border-gray-200
                    bg-gray-100
                    text-left text-xs
                    font-semibold
                    text-gray-700
                    uppercase
                    tracking-wider
                  "
                >
                  Name
                </th>
                <th
                  class="
                    px-5
                    py-3
                    border-b-2 border-gray-200
                    bg-gray-100
                    text-left text-xs
                    font-semibold
                    text-gray-700
                    uppercase
                    tracking-wider
                  "
                >
                  Date
                </th>
                <th
                  class="
                    px-5
                    py-3
                    border-b-2 border-gray-200
                    bg-gray-100
                    text-left text-xs
                    font-semibold
                    text-gray-700
                    uppercase
                    tracking-wider
                  "
                >
                  Scanned By
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(attendance, index) in attendances.data.data"
                :key="index"
              >
                <!-- <tr> -->
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {{ getserialNumber(attendances, index) }}
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">
                    {{ attendance.guest.name }}
                  </p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">
                    {{ attendance.day_of_talk }}
                  </p>
                </td>
                <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p class="text-gray-900 whitespace-no-wrap">
                    {{ attendance.user.name }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- pagination -->
      <div class="flex justify-center mt-5">
        <nav
          class="relative z-0 inline-flex justify-center rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <a
            v-for="(link, i) of attendances.links"
            :key="i"
            :disabled="!link.url"
            href="#"
            @click="getForPage($event, link)"
            aria-current="page"
            class="
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
              whitespace-nowrap
            "
            :class="[
              link.active
                ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
              i === 0 ? 'rounded-l-md' : '',
              i === attendances.links.length - 1 ? 'rounded-r-md' : '',
            ]"
            v-html="link.label"
          >
          </a>
        </nav>
      </div>
    </div>
  </PageComponent>
</template>

<script setup>
import PageComponent from "@/components/Layouts/pageComponent.vue";
import LoadindView from "@/components/LoadindView.vue";
import store from "@/store";
import { computed } from "@vue/runtime-core";

const attendances = computed(() => store.state.attendance);

const getserialNumber = (attendances, index) => {
  return attendances.data.current_page * 1 - 1 + index + 1;
};
store.dispatch("getAttendances");
function getForPage(ev, link) {
  ev.preventDefault();

  if (!link.url || link.active) {
    return;
  }
  store.dispatch("getAttendances", { url: link.url });
}
</script>

<style>
</style>