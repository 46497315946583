<template>
  <PageComponent title="DASHBOARD">
    <!-- component -->
    <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
      <div
        class="
          min-w-0
          rounded-lg
          shadow-xs
          overflow-hidden
          bg-white
          dark:bg-gray-800
        "
      >
        <div class="p-4 flex items-center">
          <div
            class="
              p-3
              rounded-full
              text-orange-500
              dark:text-orange-100
              bg-orange-100
              dark:bg-orange-500
              mr-4
            "
          >
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5">
              <path
                d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
              ></path>
            </svg>
          </div>
          <div>
            <p
              class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
              Guests
            </p>
            <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {{ counts.data.guests }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="
          min-w-0
          rounded-lg
          shadow-xs
          overflow-hidden
          bg-white
          dark:bg-gray-800
        "
      >
        <div class="p-4 flex items-center">
          <div
            class="
              p-3
              rounded-full
              text-orange-500
              dark:text-orange-100
              bg-orange-100
              dark:bg-orange-500
              mr-4
            "
          >
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5">
              <path
                d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
              ></path>
            </svg>
          </div>
          <div>
            <p
              class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
             Volunteers
            </p>
            <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {{ counts.data.users }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="
          min-w-0
          rounded-lg
          shadow-xs
          overflow-hidden
          bg-white
          dark:bg-gray-800
        "
      >
        <div class="p-4 flex items-center">
          <div
            class="
              p-3
              rounded-full
              text-orange-500
              dark:text-orange-100
              bg-orange-100
              dark:bg-orange-500
              mr-4
            "
          >
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5">
              <path
                d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
              ></path>
            </svg>
          </div>
          <div>
            <p
              class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
              Attendance Day 1
            </p>
            <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {{ counts.data.day_1_count || 0 }}
            </p>
          </div>
        </div>
      </div>

      <div
      class="
        min-w-0
        rounded-lg
        shadow-xs
        overflow-hidden
        bg-white
        dark:bg-gray-800
      "
    >
      <div class="p-4 flex items-center">
        <div
          class="
            p-3
            rounded-full
            text-orange-500
            dark:text-orange-100
            bg-orange-100
            dark:bg-orange-500
            mr-4
          "
        >
          <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5">
            <path
              d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
            ></path>
          </svg>
        </div>
        <div>
          <p
            class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
          >
            Attendance Day 2
          </p>
          <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
            {{ counts.data.day_2_count || 0 }}
          </p>
        </div>
      </div>
    </div>
      <div
        class="
          min-w-0
          rounded-lg
          shadow-xs
          overflow-hidden
          bg-white
          dark:bg-gray-800
        "
      >
        <div class="p-4 flex items-center">
          <div
            class="
              p-3
              rounded-full
              text-orange-500
              dark:text-orange-100
              bg-orange-100
              dark:bg-orange-500
              mr-4
            "
          >
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-5 h-5">
              <path
                d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
              ></path>
            </svg>
          </div>
          <div>
            <p
              class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
            >
              Unique Attendance
            </p>
            <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
              {{ counts?.data?.unique_guest_attendance || 0 }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </PageComponent>
</template>

<script setup>
import PageComponent from "@/components/Layouts/pageComponent.vue";
import LoadindView from "@/components/LoadindView.vue";
import store from "@/store";

import { computed } from "@vue/runtime-core";
const counts = computed(() => store.state.counts);

store.dispatch("getCounts");
</script>

