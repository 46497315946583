<template>
  <PageComponent>
    <template v-slot:header>
      <div class="flex justify-between items-center">
        <h1 class="text-3xl font-bold text-gray-900">GENERAL</h1>
        <!-- <router-link
          :to="{ name: 'GuestCreate' }"
          class="
            py-2
            px-3
            text-white
            bg-emerald-500
            rounded-md
            hover:bg-emerald-600
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 inline-block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4v16m8-8H4"
            />
          </svg>
          Add an Attendee
        </router-link> -->
      </div>
    </template>
    <div v-if="loading">
      <LoadindView />
    </div>
    <div v-else class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
      <div class="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="
                  px-5
                  py-3
                  border-b-2 border-gray-200
                  bg-gray-100
                  text-left text-xs
                  font-semibold
                  text-gray-700
                  uppercase
                  tracking-wider
                "
              >
                S/N
              </th>
              <th
                class="
                  px-5
                  py-3
                  border-b-2 border-gray-200
                  bg-gray-100
                  text-left text-xs
                  font-semibold
                  text-gray-700
                  uppercase
                  tracking-wider
                "
              >
                Name
              </th>
              <th
                class="
                  px-5
                  py-3
                  border-b-2 border-gray-200
                  bg-gray-100
                  text-left text-xs
                  font-semibold
                  text-gray-700
                  uppercase
                  tracking-wider
                "
              >
                Gender
              </th>
              <!-- <th
                class="
                  px-5
                  py-3
                  border-b-2 border-gray-200
                  bg-gray-100
                  text-left text-xs
                  font-semibold
                  text-gray-700
                  uppercase
                  tracking-wider
                "
              >
                Profession
              </th> -->
              <th
                class="
                  px-5
                  py-3
                  border-b-2 border-gray-200
                  bg-gray-100
                  text-left text-xs
                  font-semibold
                  text-gray-700
                  uppercase
                  tracking-wider
                "
              >
                Referer
              </th>
              <!-- <th
                class="
                  px-5
                  py-3
                  border-b-2 border-gray-200
                  bg-gray-100
                  text-left text-xs
                  font-semibold
                  text-gray-700
                  uppercase
                  tracking-wider
                "
              >
                Date
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(guest, index) in guests.data" :key="index">
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
            
                {{getserialNumber(guests, index)}}
                <!-- {{ guests.current_page * 5 - 5 + index + 1 }} -->
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-no-wrap">{{ guest.name }}</p>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-no-wrap">
                  {{ guest.gender }}
                </p>
              </td>
              <!-- <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-no-wrap">
                  {{ guest.profession }}
                </p>
              </td> -->
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-no-wrap">
                  {{ guest.referer }}
                </p>
              </td>
              <!-- <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p class="text-gray-900 whitespace-no-wrap">
                  {{ guest.qrcode }}
                </p>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex justify-center mt-5">
      <nav
        class="relative z-0 inline-flex justify-center rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <a
          v-for="(link, i) of guests.links"
          :key="i"
          :disabled="!link.url"
          href="#"
          @click="getForPage($event, link)"
          aria-current="page"
          class="
            relative
            inline-flex
            items-center
            px-4
            py-2
            border
            text-sm
            font-medium
            whitespace-nowrap
          "
          :class="[
            link.active
              ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
              : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
            i === 0 ? 'rounded-l-md' : '',
            i === guests.links.length - 1 ? 'rounded-r-md' : '',
          ]"
          v-html="link.label"
        >
        </a>
      </nav>
    </div>
  </PageComponent>
</template>

<script setup>
import PageComponent from "@/components/Layouts/pageComponent.vue";
import store from "@/store";
import { computed } from "@vue/runtime-core";
import LoadindView from "@/components/LoadindView.vue";

const guests = computed(() => store.state.guests.data);
const loading = computed(() => store.state.guests.loading);
const  getserialNumber = (((guests,index) => {
  return (guests.current_page * 20) - 20 + index + 1;
}));
store.dispatch("getGuests");
function getForPage(ev, link) {
  ev.preventDefault();

  if (!link.url || link.active) {
    return;
  }
  store.dispatch("getGuests", { url: link.url });
}

// function getOverallIndex(index){

// }
</script>

<style>
</style>