<template>
  <div
    class="
      w-screen
      h-screen
      flex flex-col
      justify-center
      items-center
      space-y-5
    "
  >
    <h1 class="text-6xl text-amber-500 font-bold">404</h1>
    <h3 class="text-3xl font-medium">Page Not Found</h3>
    <p>The Page you are trying to access does not exist</p>
    <div class="flex space-x-2">
      <button class="p-2 bg-amber-500 text-white rounded-md" @click="goHome">
        Go Back Home
      </button>
      <button>Contact Michael</button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
const goHome = () => {
  router.push("/");
};
</script>