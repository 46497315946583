import {
    createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';


import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
createApp(App).use(store).use(router).use(VueSweetalert2).use(SimpleTypeahead).mount("#app");