import {
  createRouter,
  createWebHistory
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import GuestView from "../views/GuestView.vue";
import GuestCreateView from "../views/GuestCreateView.vue";
import LoginView from "../views/Auth/LoginView.vue";
import ScannerView from "../views/ScannerView.vue";
import AuthLayout from "../components/Layouts/AuthLayout.vue";
import DashboardView from "../views/DashboardView.vue";
import DefaultLayout from "../components/Layouts/DefaultLayout.vue";
import AttendanceView from "../views/AttendanceView.vue";
import AttendanceCreateView from "../views/AttendanceCreateView.vue";
import NotFoundComponent from "../components/NotFoundComponent.vue";
import Event from "../views/Event.vue";
import EventAdd from "../views/EventAdd.vue";

import store from "@/store";
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  //   routes,
  // });
  routes: [

    {
      path: "/",
      redirect: "/dashboard",
      component: DefaultLayout,
      meta: {
        requiresAuth: true
      },
      children: [{
          path: "/dashboard",
          name: "Dashboard",
          component: DashboardView
        },
        {
          path: "/guests",
          name: "Guests",
          component: GuestView
        },
        {
          path: "/guest/create",
          name: "GuestCreate",
          component: GuestCreateView
        },
        {
          path: "/guest/:id",
          name: "GuestUpdate",
          component: GuestCreateView
        },
        {
          path: "/scanner",
          name: "Scanner",
          component: ScannerView,
        },
        {
          path: "/home",
          name: "home",
          component: HomeView,
        },
        {
          path: "/attendance",
          name: "attendance",
          component: AttendanceView
        },
        {
          path: "/attendance/create",
          name: "AttendanceCreate",
          component: AttendanceCreateView
        },
        {
          path: "/event",
          name: "event",
          component: Event
        },
        {
          path: '/event-create',
          name: 'Event-create',
          component: EventAdd
        }

      ]
    },
    {
      path: "/auth",
      redirect: "/login",
      name: "Auth",
      component: AuthLayout,
      meta: {
        isGuest: true
      },
      children: [{
        path: "/login",
        name: "login",
        component: LoginView
      }, ]
    },
    {
      path: '/:pathMatch(.*)',
      name: "404",
      component: NotFoundComponent
    },


    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import( /* webpackChunkName: "about" */ "../views/AboutView.vue"),
    // },import { ScannerView } from '..';
    // import {
    //   AttendanceView
    // } from '..';

  ]
});
// const router = createRouter({
//   // history: createWebHistory(process.env.BASE_URL),
//   history: createWebHistory(),
//   routes,
// });

router.beforeEach((to, from, next) => {

  document.title = to.name + " | Lagos Leather Fair "
  if (to.meta.requiresAuth && !store.state.user.token) {
    next({
      name: "login"
    })
  } else if (store.state.user.token && (to.meta.isGuest)) {
    next(({
      name: "Dashboard"
    }));
  } else {
    next();
  }
})

export default router;