<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-200 dark:bg-gray-900">
    <form @submit.prevent="onSubmit" class="bg-white dark:bg-gray-800 p-8 rounded shadow-md">
      <div class="flex md:flex-row flex-col items-center mb-4">
        <div class="flex flex-col md:mr-4 mb-4 md:mb-0">
          <label for="email" class="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">Email</label>
          <input
            id="email"
            v-model="email"
            type="email"
            class="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 bg-white dark:bg-gray-800 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow"
            placeholder="example@example.com"
            :disabled="qrcode !== ''"
          />
        </div>
        <div class="flex flex-col">
          <label for="qrcode" class="text-gray-800 dark:text-gray-100 text-sm font-bold leading-tight tracking-normal mb-2">QR CODE</label>
          <input
            id="qrcode"
            v-model="qrcode"
            type="text"
            class="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 bg-white dark:bg-gray-800 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border shadow"
            placeholder="LLF-2023-0000000"
            :disabled="email !== ''"
          />
        </div>
      </div>
      <div>
        <button
          type="submit"
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          :disabled="email === '' && qrcode === ''"
        >
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon
              class="h-5 w-5 text-gray-900 group-hover:text-indigo-400"
              aria-hidden="true"
            />
          </span>
          Save Guest
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import store from "@/store";
import Swal from "sweetalert2";
import { ref } from "vue";

const email = ref("");
const qrcode = ref("");

function onSubmit(ev) {
  ev.preventDefault();
  const data = {
    email: email.value,
    qrcode: qrcode.value,
  };

  if (email.value !== "" && qrcode.value !== "") {
    Swal.fire({
      title: "Error!",
      text: "Please enter either Email or QR Code",
      icon: "error",
      confirmButtonText: "OK",
    });
    return;
  }

  console.log("RESPONSE", data);
  store
    .dispatch("savedEventys", data)
    .then((response) => {
      email.value = "";
      qrcode.value = "";
      console.log("response", response?.response?.data?.message);

      Swal.fire({
        title: "Success!",
        text: "Attendance has been added",
        icon: "success",
        confirmButtonText: "OK",
      });
    })
    .catch((err) => {
      const err_msg = err?.response?.data?.message ?? "You cannot add, right now";
      console.log("ERROR", err?.response?.data?.message);
      Swal.fire({
        title: "Error!",
        text: err_msg,
        icon: "error",
        confirmButtonText: "OK",
      });
    });
}
</script>
