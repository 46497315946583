import axiosClient from "@/axios";
import {
  createStore
} from "vuex";

export default createStore({
  state: {
    logo: "https://ik.imagekit.io/ycwiiy2r8a/LagosLeatherFair/logo-light_ri7Gl1nxy.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1651768435437",
    user: {
      data: {},
      token: sessionStorage.getItem("TOKEN"),
    },
    events: {
      loading: false,
      data: {},
      links: {}
    },
    eventys: {
      loading: false,
      data: {},
      links: {}
    },
    guests: {
      loading: false,
      data: [],
      links: []

    },

    guestsAttendance: {
      loading: false,
      data: [],
    },

    scannedGuest: {
      loading: false,
      data: []
    },
    attendance: {
      loading: false,
      links: [],
      data: []
    },
    counts: {
      loading: false,
      data: [],
    }
  },
  getters: {},
  mutations: {
    logout: (state) => {
      state.user.data = {};
      state.user.token = null
      sessionStorage.removeItem('TOKEN');
    },
    setLoading: (state, loading) => {
      state.attendance.loading = loading;
    },
    setCountLoading: (state, loading) => {
      state.counts.loading = loading;
    },
    setCount: (state, countsData) => {
      state.counts.data = countsData;
    },

    setUser: (state, userData) => {
      state.user.token = userData.token;
      state.user.data = userData.user;
      sessionStorage.setItem("TOKEN", userData.token);
    },
    setGuestsLoading: (state, loading) => {
      state.guests.loading = loading
    },

    setGuestsAttendanceLoading: (state, loading) => {
      state.guestsAttendance.loading = loading
    },
    setGuestsAttendance: (state, guestsAttendanceData) => {
      state.guestsAttendance.data = guestsAttendanceData;
    },
    setScannedGuestLoading: (state, loading) => {
      state.scannedGuest.loading = loading
    },
    setEventsLoading: (state, loading) => {
      state.events.loading = loading
    },
    setEventysLoading: (state, loading) => {
      state.eventys.loading = loading
    },
    setGuests: (state, guests) => {

      state.guests.data = guests
      state.guests.data.links = guests.links

    },
    setEvents: (state, events) => {
      state.events.data = events
      state.events.links = events.links
    },
    setEventys: (state, eventys) => {
      state.eventys.data = eventys
      state.eventys.links = eventys.links
    },
    setScannedGuest: (state, scannedGuest) => {

      state.scannedGuest.data = scannedGuest;
    },
    setSavedEvents: (state, events) => {
      state.events.data = events;
    },
    setSavedEventys: (state, eventys) => {
      state.eventys.data = eventys;
    },
    setAttendance: (state, attendanceData) => {
      state.attendance.data = attendanceData;
      state.attendance.links = attendanceData.links;
    }
  },
  actions: {
    login({
      commit
    }, user) {
      return axiosClient.post('/login', user)
        .then(({
          data
        }) => {
          commit("setUser", data);
          return data;
        })
    },

    scannedGuest({
      commit
    }, guests) {
      commit("setScannedGuestLoading", true);
      return axiosClient.post('/attendance', guests).then(({
        data
      }) => {
        commit("setScannedGuestLoading", false);
        commit("setScannedGuest", data);
        return data;
      }).catch((err) => {
        commit("setScannedGuestLoading", false);
        throw err;
      })
    },

   savedEvents({
     commit
   }, events){
     commit("setEventsLoading", true);
     return axiosClient.post('/guest/on-site-reg', events).then(({data}) => {
        commit("setEventsLoading", false);
        commit("setSavedEvents", data);
        return data;
     }).catch((err) => {
      commit("setEventsLoading", false);
      throw err;
     })
   },
   savedEventys({
    commit
  }, eventys){
    commit("setEventysLoading", true);
    return axiosClient.post('/guest/day-attendance', eventys).then(({data}) => {
       commit("setEventysLoading", false);
       commit("setSavedEventys", data);
       return data;
    }).catch((err) => {
     commit("setEventysLoading", false);
     throw err;
    })
  },
    logout({
      commit
    }) {
      return axiosClient.post('/user/logout')
        .then(response => {
          commit('logout')
          return response
        })
    },
    getGuests({
      commit
    }, {
      url = null
    } = {}) {
      url = url || '/guest'
      commit("setGuestsLoading", true);
      return axiosClient.get(url)
        .then((res) => {
          commit("setGuests", res.data);
          commit("setGuestsLoading", false);
          return res;
        }).catch((err) => {
          commit("setGuestsLoading", false);
          throw err;
        });
    },

    getEvents({
      commit
    }, {
      url = null
    } = {}) {
      url = url || '/guest/on/site-activities'
      commit("setEventsLoading", true);
      return axiosClient.get(url)
        .then((res) => {
          commit("setEvents", res.data);
          commit("setEventsLoading", false);
          return res;
        }).catch((err) => {
          commit("setEventsLoading", false);
          throw err;
        });
    },

    getCounts({
      commit
    }) {
      commit("setCountLoading", true);
      return axiosClient.get("/user/dashboard")
        .then((res) => {
          commit("setCount", res.data);
          commit("setCountLoading", false);
          return res.data
        }).catch((err) => {
          commit("setCountLoading", false);
          throw err;
        })
    },

    getGuestsAttendance({
      commit
    }) {
      commit("setGuestsAttendanceLoading", true);
      return axiosClient.get("/attendance/guests")
        .then((res) => {
          commit("setGuestsAttendance", res.data);
          commit("setGuestsAttendanceLoading", false);
          return res.data
        }).catch((err) => {
          commit("setGuestsAttendanceLoading", false);
          throw err;
        })
    },

    getAttendances({
      commit
    }, {
      url = null
    } = {}) {
      url = url || '/attendance'
      commit("setLoading", true);
      return axiosClient.get(url)
        .then((res) => {
          commit("setAttendance", res.data);
          commit("setLoading", false);
          return res;
        }).catch((err) => {
          commit("setLoading", false);
          throw err;
        })
    }
  },
  modules: {},
});