<template>
  <page-component title="view or create a guest">
    view or create a guest
  </page-component>
</template>

<script setup>
import PageComponent from "@/components/Layouts/pageComponent.vue";
import store from "@/store";
import { computed } from "@vue/runtime-core";

const guests = computed(() => store.state.guests);
</script>

<style>
</style>